import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
	// 主页
  {
    path: '/',
    name: 'HomePage',
    component: () => import("../views/HomePage.vue"),
	meta: {
	    showTab: true
	}
  },
  // 扫码导览
  {
  	path: "/qrcode",
  	name: "qrcode",
  	component: () => import("../views/qrcode.vue"),
  },
  // 扫码导览
  {
  	path: "/exhibit",
  	name: "exhibit",
  	component: () => import("../views/exhibit.vue"),
  },
  // 我的
  {
  	path: "/user",
  	name: "user",
  	component: () => import("../views/user.vue"),
	meta: {
	    showTab: true
	}
  },
  // 门票预约
  {
  	path: "/ticket",
  	name: "ticket",
  	component: () => import("../views/ticket.vue"),
  },
  // 活动课程
  {
  	path: "/activeclass",
  	name: "activeclass",
  	component: () => import("../views/activeclass.vue"),
  },
  // 活动内容页
  {
  	path: "/message",
  	name: "message",
  	component: () => import("../views/message.vue"),
  },
  // 预约记录
  {
  	path: "/reservation",
  	name: "reservation",
  	component: () => import("../views/reservation.vue"),
  },
  // 预约购票
  {
  	path: "/booking",
  	name: "booking",
  	component: () => import("../views/booking.vue"),
  },
  // 预约购票
  {
  	path: "/bookingadduser",
  	name: "bookingadduser",
  	component: () => import("../views/bookingadduser.vue"),
  },
  // 意见反馈
  {
  	path: "/opinion",
  	name: "opinion",
  	component: () => import("../views/opinion.vue"),
  },
  // 志愿者服务
  {
  	path: "/volunteer",
  	name: "volunteer",
  	component: () => import("../views/volunteer.vue"),
  },
  // 志愿者招募
  {
  	path: "/volunteeradd",
  	name: "volunteeradd",
  	component: () => import("../views/volunteeradd.vue"),
  },
  // 志愿者招募
  {
  	path: "/volunteerform",
  	name: "volunteerform",
  	component: () => import("../views/volunteerform.vue"),
  },
 
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
