import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
	Tab,
	Tabs,
	Tabbar,
	TabbarItem,
	NavBar,
	Stepper,
	RadioGroup,
	Radio,
	Popup,
	Checkbox,
	CheckboxGroup,
	Field,
	Cell,
	CellGroup,
	Picker,
	Empty,
	Uploader,
	Dialog
} from 'vant';
import 'vant/lib/index.css';

createApp(App).use(store).use(router).use(Tab).use(Tabs).use(Tabbar).use(TabbarItem).use(NavBar).use(Stepper).use(
	RadioGroup).use(Radio).use(Popup).use(Checkbox).use(CheckboxGroup).use(Field).use(Cell).use(CellGroup).use(
	Picker).use(Empty).use(Uploader).use(Dialog).mount('#app')
